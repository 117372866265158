"use client";
import { LoggerEvents, logger } from "@bw/core";
import { MortgageVerificationForm, MortgageVerificationTrackingIdRequestData } from "@bw/external-apis";
import { useRouter } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm, useWatch } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Home, MonetizationOn, Person, RealEstateAgent, Shield } from "../../../../elements/src/svgs/icons";
import SvgLoaderBlue from "../../../../elements/src/svgs/icons/LoaderBlue";
import { FormCheckboxInput, FormFileInput, FormMetaState, FormRadioInputList, FormTextInput, FormZipCodeInput } from "../form-elements";
import { FormBottomToast } from "../form-elements/formBottomToast/formBottomToast";
import FormButtonGrow from "../form-elements/formButtonGrow/formButtonGrow";
import FormButton from '../utility/formButton/formButton';
import styles from './mortgage-verification-form.module.scss';

export interface MortgageVerificationFormProps {
  TrackingId: string;
}

const insurancePaymentsOptions = [
  {
    label: 'Yes, my mortgage company makes payments.',
    value: 'mortgage company pays',
  },
  {
    label: 'No, I make payments.',
    value: 'self pay',
  },
  {
    label: 'I\'m not sure.',
    value: 'unsure',
  },
];

const insurancePaymentsNoLongerOwnOptions = [
  {
    label: 'Yes, I\'ve been making payments.',
    value: 'self pay',
  },
  {
    label: 'I need to start making payments.',
    value: 'need to start making',
  },
  {
    label: 'I\'m not sure.',
    value: 'unsure',
  },
];

const getUpdateStatus = ({ policyChangedFields, mortgageChangedFields, paidOffMortgage, noLongerOwn, insurancePayments }) => {
  if (noLongerOwn) {
    return 'NoLongerOwn';
  }

  if (paidOffMortgage) {
    return 'Paid';
  }

  if (mortgageChangedFields.length > 0 || 'mortgage company pays' !== insurancePayments) {
    return 'MortgageeChange';
  }

  if (policyChangedFields.length > 0) {
    return 'Updated';
  }

  return 'Confirmed';
}

// captcha
const getCaptchaToken = async (
  action: string | undefined,
  execute: ((action?: string | undefined) => Promise<string>) | undefined
): Promise<string> => {

  if (!execute) {
    const message = 'executeRecaptcha not available on form submit, possible react script loading error.';
    logger.error(LoggerEvents.GeneralErrorWebApp, { message });
    return 'no recaptcha';
  }

  return await execute(action);
}

export const MortgageDataTrackingIdForm = ({ handleSubmit }) => {
  const formContext = useForm<MortgageVerificationTrackingIdRequestData>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  async function handleSubmitCallback(data) {
    await handleSubmit({
      data,
      captchaToken: await getCaptchaToken('mortgageVerificationForm', executeRecaptcha),
    });
  }

  return (
    <div className={styles.mortgageVerificationContainer}>
      <div className={styles.preHeader} >
        <h1>Hi there!</h1>
      </div>
      <form onSubmit={formContext.handleSubmit(handleSubmitCallback)}>
        <div className={styles.preSection} >
          <div className={styles.controls} >
            <FormTextInput id="Email" label="Email address" placeholder={'Enter your email address...'} context={formContext} />
            <FormTextInput id="FriendlyId" label="Code" placeholder={'Enter the code from your letter...'} required context={formContext} />
            <FormZipCodeInput id='ZipCode' label="Mailing ZIP" placeholder={'Enter your mailing address ZIP code...'} required context={formContext} />
          </div>
        </div>

        <div className={styles.preSection} >
          <div className={styles.controls} >
            <FormButton className={styles.preSubmitButton} type="submit" title='Next' />
          </div>
        </div>
      </form>
    </div>
  );
}

export const MortgageDataForm = ({ defaultValues, submitMortgageVerification }: { defaultValues: any, submitMortgageVerification: any }) => {
  const router = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formContext = useForm<MortgageVerificationForm>({ defaultValues });
  const { control, handleSubmit, formState, formState: { dirtyFields } } = formContext;
  const noLongerOwn = useWatch({ control, name: 'NoLongerOwn' });
  const paidOffMortgage = useWatch({ control, name: 'PaidOffMortgage' });
  const [ formMetaState, setFormMetaState ] = useState(FormMetaState.loading);

  // custom meta state logic for UX improvements
  useEffect(() => {
    if (formState.isLoading) {
      // initial loading state
      setFormMetaState(FormMetaState.loading);
    } else if (formState.isSubmitting) {
      // form is currently submitting
      setFormMetaState(FormMetaState.submitting);
    } else if (!formState.isSubmitted) {
      // keep form open until submitted at least once
      setFormMetaState(FormMetaState.open);
    } else if (navigator?.onLine === false) {
      // internet connection problem, disallow submission
      setFormMetaState(FormMetaState.offline);
      formContext.clearErrors('root');
    } else if (formMetaState === FormMetaState.offline && navigator?.onLine) {
      // back online, clear root errors and allow submit
      setFormMetaState(FormMetaState.online);
      formContext.clearErrors('root');
    } else if (formState.errors.root) {
      // there was a server error
      if ([FormMetaState.retry, FormMetaState.fail].includes(formMetaState) && formState.submitCount > 1) {
        // the form is open, but with the retry message
        setFormMetaState(FormMetaState.fail);
      } else {
        // too many failures, disallow submission
        setFormMetaState(FormMetaState.retry);
      }
    } else if (formMetaState === FormMetaState.submitting && !formState.isSubmitting) {
      // form isn't submitting anymore, so open it back up
      setFormMetaState(FormMetaState.open);
    } else if (formState.isSubmitSuccessful) {
      // final success state, transition to yellow  and thank you page
      document.body.classList.add(styles.isSubmitted);
      setFormMetaState(FormMetaState.success);
    }
  }, [formState]);

  const isDisabled = [FormMetaState.success, FormMetaState.submitting, FormMetaState.fail, FormMetaState.offline].includes(formMetaState);

  const nameChanged = useMemo(
    () => (dirtyFields.InsuredFirstName || dirtyFields.InsuredLastName),
    [dirtyFields.InsuredFirstName, dirtyFields.InsuredLastName]);

  // handle form submission
  async function handleSubmitCallback(data) {
    const policyChangedFields = [
      'InsuredFirstName', 'InsuredLastName', 'Email', 'CustomerPhoneNumber', 'Description',
    ]
      .filter(key => dirtyFields[key]);
    const mortgageChangedFields = ['InterestedPartyName1', 'LoanNumber', 'PaidOffMortgage']
      .filter(key => dirtyFields[key]);

    const formSubmission = {
      UpdateStatus: getUpdateStatus({
        policyChangedFields,
        mortgageChangedFields,
        paidOffMortgage,
        noLongerOwn,
        insurancePayments: data.InsurancePayments
      }),
      
      // meta data needed for processing
      captchaToken: await getCaptchaToken('mortgageVerificationForm', executeRecaptcha),
      Id: data.Id,
      TrackingId: data.TrackingId,
      PolicyId: data.PolicyId,
      CustomerId: data.CustomerId,

      // read-only data to be displayed to user
      PolicyNumber: data.PolicyNumber,
      CompanyName: data.CompanyName,

      // form logic/flow controls
      PaidOffMortgage: data.PaidOffMortgage,
      NoLongerOwn: data.NoLongerOwn,
      InsurancePayments: data.InsurancePayments,
      IsMortgageeBilled: ['self pay', 'need to make'].includes(data.MortgagePayments),

      // form data fields
      InsuredFirstName: data.InsuredFirstName,
      InsuredLastName: data.InsuredLastName,
      Email: data.Email,
      CustomerPhoneNumber: data.CustomerPhoneNumber,
      Description: data.Description,
      InterestedPartyName1: data.InterestedPartyName1,
      LoanNumber: data.LoanNumber
    } as MortgageVerificationForm;

    formSubmission.UpdateStatus = getUpdateStatus({
      policyChangedFields,
      mortgageChangedFields,
      paidOffMortgage,
      noLongerOwn,
      insurancePayments: data.InsurancePayments
    });

    if (nameChanged && data.NameChangeFile) {
      formSubmission.NameChangeFileEncoded = {
        FileName: data.NameChangeFile.name,
        ContentType: data.NameChangeFile.type,
        ContentLength: data.NameChangeFile.size,
        Content: data.NameChangeFile.encoded?.split(',')?.[1],
      };

      delete data.NameChangeFile;
    }

    if (noLongerOwn) {
      formSubmission.ClosingStatementFileEncoded = {
        FileName: data.ClosingStatementFile.name,
        ContentType: data.ClosingStatementFile.type,
        ContentLength: data.ClosingStatementFile.size,
        Content: data.ClosingStatementFile.encoded?.split(',')?.[1],
      };

      delete data.ClosingStatementFile;
    }

    if (paidOffMortgage) {
      formSubmission.LoanSatisfactionFileEncoded = {
        FileName: data.LoanSatisfactionFile.name,
        ContentType: data.LoanSatisfactionFile.type,
        ContentLength: data.LoanSatisfactionFile.size,
        Content: data.LoanSatisfactionFile.encoded?.split(',')?.[1]
      };

      delete data.LoanSatisfactionFile;
    }

    try {
      if (navigator.onLine === false) {
        throw 'offline';
      }

      const response = await submitMortgageVerification(formSubmission);
      if (response.errors) {
        throw response.errors.join(',');
      }

      router.push(`/mortgage-verification/thank-you?trackingId=${defaultValues.TrackingId}`);
    } catch (error: any) {
      formContext.setError('root.serverError', { 
        type: 'manual', 
        message: error?.message ?? error
      });
    }
  }

  return (
    <div className={styles.mortgageVerificationContainer}>
      <div className={styles.header}>
        <div><h1>Let's get you verified</h1></div>
        <p>Thanks for being proactive and tackling this now. Here's all we need you to do:</p>
        <div className={styles.list}>
          <div className={styles.listItem}>
            <div>1</div>
            <span><em>Review</em> the info below</span>
          </div>
          <div className={styles.listItem}>
            <div>2</div>
            <span><em>Correct</em> any mistakes</span>
          </div>
          <div className={styles.listItem}>
            <div>3</div>
            <span><em>Enjoy</em> the rest of your day!</span>
          </div>
        </div>
      </div>

      {formMetaState === FormMetaState.loading ? (
        <div className={styles.loader}>
          <SvgLoaderBlue className={styles.spinner} width={255} height={255} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(handleSubmitCallback)}>
          <fieldset disabled={isDisabled}>
            <div className={styles.section} >
              <div className={styles.sectionHeader}>
                <div className={styles.icon}>
                  <Person width={24} height={24} />
                </div>
                <div><h3>Personal</h3></div>
                <div className={styles.divider} />
              </div>
              <div className={styles.controls}>
                <div className={styles.controlRow}>
                  <FormTextInput
                    id="InsuredFirstName"
                    label="First name"
                    required
                    context={formContext}
                  />
                  <FormTextInput
                    id="InsuredLastName"
                    label="Last name"
                    required
                    context={formContext}
                  />
                </div>
                <div className={styles.controlRow}>
                  <FormTextInput
                    type="email"
                    inputMode="email"
                    id="Email"
                    label="Email address"
                    context={formContext}
                  />
                  <FormTextInput
                    type="tel"
                    inputMode="tel"
                    id="CustomerPhoneNumber"
                    label="Phone number"
                    required
                    context={formContext}
                  />
                </div>
                {nameChanged && <div className={styles.fileInputRow}>
                  <FormFileInput id="NameChangeFile" context={formContext}>
                    <p>
                      Did we have the wrong name? Please <em>send a copy of a government-issued photo ID</em>.
                    </p>
                  </FormFileInput>
                </div>}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <div className={styles.icon}>
                  <Home width={24} height={24} />
                </div>
                <div>
                  <h3>Property</h3>
                </div>
                <div className={styles.divider} />
              </div>
              <div className={styles.controls}>
                <div className={styles.controlRow}>
                  <FormTextInput
                    id="Description"
                    label="Address"
                    required
                    context={formContext}
                  />
                  <div className={styles.filler} />
                </div>
                <div className={styles.controlRow}>
                  <FormCheckboxInput
                    id="NoLongerOwn"
                    label="I don't own this property anymore"
                    context={formContext}
                  />
                </div>
                {(noLongerOwn == true) && <div className={styles.fileInputRow}>
                  <FormFileInput
                    id="ClosingStatementFile"
                    context={formContext}
                    required
                  >
                    <p>
                      Thanks for letting us know. Please <em>send us a copy of your <a
                        data-tooltip-id="mv-form-tooltip"
                        data-tooltip-content={
                          "This is a document that lists all the money\
                          that changed hands when you sold this\
                          property."
                        }
                        data-tooltip-place="top"
                        className={styles.tooltipText}
                      >Closing Statement</a></em>
                      .
                    </p>
                  </FormFileInput>
                </div>}
              </div>
            </div>

            {!noLongerOwn && (<div className={styles.section}>
              <div className={styles.sectionHeader}>
                <div className={styles.icon}><Shield width={24} height={24} /></div>
                <div><h3>Insurance Policy</h3></div>
                <div className={styles.divider} />
              </div>
              <div className={styles.controls}>
                <div className={styles.controlRow}>
                  {/* This info should not be updated and is view only */}
                  <FormTextInput
                    id="PolicyNumber"
                    label="Policy number"
                    required
                    disabled
                    context={formContext}
                  />
                  <FormTextInput
                    id="CompanyName"
                    label="Insurance company name"
                    required
                    disabled
                    context={formContext}
                  />
                </div>
              </div>
            </div>)}

            {!noLongerOwn && (<div className={styles.section}>
              <div className={styles.sectionHeader}>
                <div className={styles.icon}>
                  <RealEstateAgent width={24} height={24} />
                </div>
                <div><h3>Mortgage</h3></div>
                <div className={styles.divider} />
              </div>
              <div className={styles.controls}>
                <div className={styles.controlRow}>
                  <FormTextInput
                    id="InterestedPartyName1"
                    label="Mortgage company name"
                    required
                    context={formContext}
                  />
                  <FormTextInput
                    id="LoanNumber"
                    required
                    context={formContext}
                  >
                    <label htmlFor="LoanNumber">
                      <a
                        data-tooltip-id="mv-form-tooltip"
                        data-tooltip-content={
                          "This is usually in your mortgage documents,\
                          or your mortgage company's website."
                        }
                        data-tooltip-place="top"
                        className={styles.tooltipText}
                      >Loan Number</a>
                    </label>
                  </FormTextInput>
                </div>
                <div className={styles.controlRow}>
                  <FormCheckboxInput
                    id="PaidOffMortgage"
                    label="I paid off my mortgage"
                    context={formContext}
                  />
                </div>
                {paidOffMortgage && <div className={styles.fileInputRow}>
                  <FormFileInput
                    id="LoanSatisfactionFile"
                    context={formContext}
                    required
                  >
                    <p>Congrats! Please <em>send us a copy of your <a
                      data-tooltip-id="mv-form-tooltip"
                      data-tooltip-content={
                        "This is the letter from your mortgage company\
                        explaining that you paid off your loan."
                      }
                      data-tooltip-place="top"
                      className={styles.tooltipText}
                    >Loan Satisfaction Letter</a></em>.</p>
                  </FormFileInput>
                </div>}
              </div>
            </div>)}

            {!noLongerOwn && (<div className={styles.section}>
              <div className={styles.sectionHeader}>
                <MonetizationOn width={24} height={24} />
                <div><h3>Payments</h3></div>
                <div className={styles.divider} />
              </div>
              {(paidOffMortgage === true) ? (<div className={styles.controls}>
                <FormRadioInputList
                  id={'InsurancePayments'}
                  required
                  context={formContext}
                  options={insurancePaymentsNoLongerOwnOptions}
                >
                  <p>
                    Since you paid off your mortgage, have you started making your own insurance payments?
                  </p>
                </FormRadioInputList>
              </div>) : (<div className={styles.controls}>
                <FormRadioInputList
                  id={'InsurancePayments'}
                  required
                  context={formContext}
                  options={insurancePaymentsOptions}
                >
                  <p>
                    Does your mortgage company make your insurance payments <a
                      data-tooltip-id="mv-form-tooltip"
                      data-tooltip-content={
                        "This is when a portion of your mortgage\
                        payments is set aside to cover other expenses."
                      }
                      data-tooltip-place="top"
                      className={styles.tooltipText}
                    >
                      (through escrow)
                    </a>?
                  </p>
                </FormRadioInputList>
              </div>)}
            </div>)}
          </fieldset>
          
          <FormButtonGrow
            id="submit"
            onClick={handleSubmit(handleSubmitCallback)}
            metaState={formMetaState}
            title={"Looks good"}
          />
        </form>
      )}
      {/* {isSubmitted && (
        <div className={styles.submitMessage}>
          <h1>Thanks, {insuredFirstName}!</h1>
          <div>
            Please wait while we save your information...
          </div>
        </div>
      )} */}
      <Tooltip id="mv-form-tooltip" className={styles.tooltipContent} />
      <FormBottomToast metaState={formMetaState} retrySubmit={handleSubmit(handleSubmitCallback)}/> 
      {/* formMetaState */}
    </div>
  )
}

export const MortgageThankYou = ({ name, ...props }) => {
  useEffect(() => {
    document.body.classList.remove(styles.isSubmitted);
  }, []);

  return (<div {...props}>
    <h1>Thanks, {name}!</h1>
    <div>You&apos;re all set.</div>
  </div>);
}