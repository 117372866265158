//code to convert img to svg
//add svg as a classname to convert img to svg
'use client';
import { useEffect } from 'react';

export const SvgConverter = () => {
	useEffect(() => {
		const convertImages = () => {
			const images = document.querySelectorAll('img.svg') as NodeListOf<HTMLImageElement>;
			images.forEach((image) => {
				fetch(image.src)
					.then((res) => res.text())
					.then((data) => {
						const parser = new DOMParser();
						const svg = parser
							.parseFromString(data, 'image/svg+xml')
							.querySelector('svg');
						if (svg) {
							svg.setAttribute(
								'viewBox',
								`0 0 ${svg.getAttribute('width')} ${svg.getAttribute('height')}`
							);
							if (image.id) svg.id = image.id;
							if (image.className) svg.setAttribute('class', image.className);
							if (image.parentNode) {
								image.parentNode.replaceChild(svg, image);
							}
						}
					})
					.catch((error) => {
						console.error('Error fetching or parsing SVG:', error);
					});
			});
		};

		convertImages();
	}, []);

	return null;
};

export default SvgConverter;
