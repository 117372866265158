import NextImage, { ImageProps } from 'next/image';

/**
 * Throws early when src is null so the error is easier to debug, and
 * sets the default quality to 98 instead of 75.
 */
export const Image: React.FC<ImageProps> = ({ src, quality = 98, ...props }) => {
  if (src == null) { throw new Error('Image src is required'); }
  return <NextImage {...{ ...props, quality, src }} />;
};
