'use client';
import { ReactLenis } from 'lenis/react';
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

export const AOSWrapper = ({ children, options, ...props }) => {
	useEffect(() => {
		AOS.init({
			duration: 300, // Duration of animation in milliseconds
			offset: 25, // Offset (in pixels) from the top of the window
			easing: 'ease-in-out', // Easing type
			anchorPlacement: 'top-bottom', // Anchor placement, which determines when animations trigger
			once: true,
		});
	}, []);


	return (
		// Lenis is a smoothscroll library
		<ReactLenis root {...props}>
			{children}
		</ReactLenis>
	);
};
