import { gql } from '@apollo/client';

export const GET_HEADER_ITEMS = gql`
	query Header($component: String!) {
		headerCollection(limit: 10, where: { name: $component }) {
			items {
				componentName
				title
				containsStartAQuoteButton
				containsBookACallButton
				logo {
					... on LogoBox {
						title
						imageUrl
						altText
						image {
							title
							description
							url
							width
							height
						}
					}
				}
				menuItemsCollection(limit: 10) {
					items {
						... on TextUrl {
							text
							url
							containsBookACallFunctionality
						}
						... on MenuItem {
							title
							url
							menuBannerImage {
								title
								url
								width
								height
							}
							subMenuCollection(limit: 13) {
								items {
									... on TextUrl {
										text
										url
										containsBookACallFunctionality
									}
									... on MenuItem {
										title
										url
										subMenuCollection(limit: 5) {
											items {
												... on TextUrl {
													text
													url
												}
												... on MenuItem {
													title
													url
												}
											}
										}
									}
								}
							}
							hideMenuActionSection
						}
					}
				}
				ctaLinksCollection {
					... on HeaderCtaLinksCollection {
						items {
							... on Button {
								title
								buttonUrl
								textColour
								containsPlayIcon
								backgroundColour
								borderColour
								type
								openInNewTab
							}
						}
					}
				}
				actionLinksCollection {
					... on HeaderActionLinksCollection {
						items {
							... on TextUrl {
								text
								url
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_FOOTER_ITEMS = gql`
	query Footer($component: String!) {
		footerCollection(limit: 10, where: { name: $component }) {
			items {
				name
				copyrightText
				containsForm
				addressHeading
				address
				addressCityStateZip
				isFranchiseFooter
				footerLogo {
					... on LogoBox {
						title
						imageUrl
						image {
							title
							url
							width
							height
						}
					}
				}
				footerListCollection(limit: 10) {
					items {
						... on ListSection {
							heading
							listItemsCollection(limit: 10) {
								items {
									... on TextUrl {
										text
										url
									}
								}
							}
						}
					}
				}
				socialIconsCollection {
					items {
						... on IconTextSection {
							name
							url
							icon {
								url
								height
								width
								title
							}
						}
					}
				}
				legalLinksCollection(limit: 10) {
					items {
						... on TextUrl {
							text
							url
						}
					}
				}
			}
		}
	}
`;

export const GET_METHOD_ITEMS = gql`
	query methods($component: String!) {
		imageTextColumnCollection(limit: 5, where: { name: $component }) {
			items {
				name
				headingTagline
				heading
				description
				image {
					image {
						title
						url
						width
						height
					}
				}
				button {
					title
					buttonUrl
					textColour
					backgroundColour
					borderColour
					openInNewTab
				}
				buttonRichTextSectionCollection {
					items {
						__typename
						... on MethodCard {
							name
							heading
							index
							image {
								url
								width
								height
								title
							}
							description {
								json
							}
							button {
								... on Button {
									title
									buttonUrl
									textColour
									containsPlayIcon
									backgroundColour
									borderColour
									type
									openInNewTab
								}
							}
							containsStartAQuoteButton
						}
					}
				}
			}
		}
	}
`;

export const GET_HERO_BANNER_ITEMS = gql`
	query HeroBanner($component: String!) {
		bannerButtonImageContainerCollection(where: { name: $component }) {
			items {
				name
				isFirstComponent
				containsStartAQuoteButton
				containsBookACallButton
				backgroundColour
				textImageSection {
					... on TextImageSection {
						heading {
							json
						}
						headingTagline
						richTextDescription {
							json
						}
						sectionImage {
							url
							title
							width
							height
						}
						imagePosition
						imageBackgroundPosition
						equalWidth
					}
				}
				buttonCollection {
					items {
						... on Button {
							title
							buttonUrl
							textColour
							containsPlayIcon
							backgroundColour
							borderColour
							type
							openInNewTab
						}
					}
				}
			}
		}
	}
`;

export const GET_MINI_BANNER_RICHTEXT_ITEMS = gql`
	query miniBannerRichText($component: String!) {
		buttonRichTextSectionCollection(limit: 2, where: { name: $component }) {
			items {
				componentName
				name
				containsStartAQuoteButton
				quoteButtonText
				containsCalendlyButton
				calendlyButtonText
				text {
					json
				}
				containButton
				buttonCollection {
					items {
						title
						buttonUrl
						textColour
						containsPlayIcon
						backgroundColour
						borderColour
						type
						openInNewTab
					}
				}
			}
		}
	}
`;

export const GET_TEXT_SLIDER_ITEMS = gql`
	query TextSlider($component: String!) {
		textSliderCollection(limit: 1, where: { name: $component }) {
			items {
				name
				sliderText {
					json
				}
			}
		}
	}
`;

export const GET_ICON_TEXT_BANNER_ITEMS = gql`
	query TextIcon($component: String!) {
		iconTextSectionCollection(where: { name: $component }) {
			items {
				name
				label
				icon {
					title
					description
					fileName
					url
					width
					height
				}
			}
		}
	}
`;

// "limit" must be defined to avoid a query complexity error
export const GET_HOMEPAGE_SLICK = gql`
	query homepageSlick($component: String!) {
		sliderBoxCollection(where: { name: $component }) {
			items {
				name
				heading
				iconCollection(limit: 50) {
					items {
						... on LogoBox {
							altText
							image {
								url
								title
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_AGENCYPAGE_NAV_INSURANCE = gql`
	query navigatingInsurance($component: String!) {
		imageRichTextButtonCollection(limit: 5, where: { name: $component }) {
			items {
				headingTagline
				headingRichText {
					json
				}
				description {
					json
				}
				image {
					... on LogoBox {
						image {
							url
							width
							height
						}
						altText
					}
				}
				imagePosition
				button {
					... on Button {
						title
						buttonUrl
						textColour
						containsPlayIcon
						backgroundColour
						borderColour
						type
					}
				}
				backgroundColor
			}
		}
	}
`;

export const GET_ABOUT_DETAILS = gql`
	query AboutDetails {
		aboutDetailsCollection {
			items {
				title
				designation
				email
				details {
					json
				}
				image {
					... on LogoBox {
						altText
						image {
							url
							height
							width
						}
					}
				}
			}
		}
	}
`;

export const GET_AGENCY_HOMEPAGE_BANNER_LOGO = gql`
	query AgencyHomePageBanner($component: String!) {
		textImageSectionCollection(where: { name: $component }) {
			items {
				name
				heading {
					json
				}
				subtitle
				imageAltText
				imagePosition
				sectionImage {
					url
					width
					height
				}
			}
		}
	}
`;

export const GET_IMAGE_COL_TEXT_FORM_ITEMS = gql`
	query InsuranceTypeForm($component: String!) {
		imageWithTextFormCollection(limit: 5, where: { name: $component }) {
			items {
				subHeading
				heading
				description
				formHeading
				isImageSmall
				optionsItems
				dropdownHeading
				formId
				image {
					... on LogoBox {
						image {
							title
							url
							width
							height
						}
					}
				}
				button {
					... on Button {
						title
						buttonUrl
						textColour
						containsPlayIcon
						backgroundColour
						borderColour
						type
					}
				}
			}
		}
	}
`;
export const GET_PAGE_LAYOUT = gql`
	query Page($component: String!) {
		pageCollection(limit: 1, where: { slug: $component }) {
			items {
				name
				slug
				title
				customClass
				seoMetadata {
					... on SeoMetadata {
						title
						metaDescription
						metaRobots
						metaDataBase
						metaImage {
							title
							url
						}
						jsonLd
					}
				}
				componentsCollection(limit: 50) {
					... on PageComponentsCollection {
						items {
							... on Header {
								name
								componentName
							}
							... on Footer {
								componentName
								name
							}

							... on BannerButtonImageContainer {
								componentName
								name
							}
							... on ButtonRichTextSection {
								name
								componentName
							}

							... on ImageTextColumn {
								name
								componentName
							}
							... on ImageButtonTextColumn {
								name
								componentName
							}
							... on SliderBox {
								name
								componentName
							}
							... on ImageWithTextForm {
								name
								componentName
							}
							... on MapComponent {
								name
								componentName
							}
							... on CompaniesWeRepresent {
								name
								componentName
							}
							... on TextSlider {
								name
								componentName
							}
							... on ImageRichTextButton {
								name
								componentName
							}
							... on FaqModel {
								name
								componentName
							}
							... on FormSection {
								name
								componentName
							}
							... on TextImageSection {
								name
								componentName
							}
							... on ListingModel {
								name
								componentName
							}
							... on StepSection {
								name
								componentName
							}
							... on HeadingSectionWithIconsAndButton {
								name
								componentName
							}
							... on HeadingImageWithNumberComponent {
								name
								componentName
							}
							... on ListSection {
								name
								componentName
							}
							... on ComaprisonModel {
								name
								componentName
							}
							... on NameRichText {
								name
								componentName
							}
							... on HeadingImageWithNumberComponent {
								name
								componentName
							}
							... on ListSection {
								name
								componentName
							}
							... on NameRichText {
								name
								componentName
							}

							... on SpecializedComponent {
								componentType
								componentName: __typename
								paramsObject
							}
						}
					}
				}
			}
		}
	}
`;

export const SEARCH_NEWSROOM_ITEMS = gql`
	query SearchNewsroomItems(
		$limit: Int!
		$skip: Int!
		$searchTerm: String!
		$tags: NewsroomItemFilter!
	) {
		newsroomItemCollection(
			order: [date_DESC]
			limit: $limit
			skip: $skip
			where: {
				AND: [
					{
						OR: [
							{ name_contains: $searchTerm }
							{ summary_contains: $searchTerm }
							{ content_contains: $searchTerm }
						]
					}
					$tags
				]
			}
		) {
			total
			items {
				name
				slug
				redirect
				tags
				date
				summary
				imageUrl
				content
				articleTitle
				pdf {
					url
				}
				featureImage {
					url
				}
			}
		}
	}
`;

export const GET_LEADERSHIP_PAGE_DETAILS = gql`
	query LeadersShipPage($component: String!) {
		logoBannerWithPersonCardsCollection(
			limit: 10
			where: { name: $component }
		) {
			items {
				name
				headingTagline
				heading
				description
				logoImage {
					... on LogoBox {
						image {
							url
							width
							height
						}
					}
				}
				personCardCollection(limit: 10) {
					items {
						... on PersonCard {
							name
							personName
							designation
							slug
							image {
								... on LogoBox {
									image {
										url
										height
										width
										title
									}
								}
							}
						}
					}
				}
				textWithButtonsComponent {
					... on ButtonRichTextSection {
						name
					}
				}
				seoData {
					... on SeoMetadata {
						title
						metaDescription
						metaRobots
						metaDataBase
						metaImage {
							title
							url
						}
						jsonLd
					}
				}
				componentName
			}
		}
	}
`;

export const GET_SINGLE_LEADER_DETAILS = gql`
	query SingleLeaderDetails($component: String!) {
		personCardCollection(limit: 10, where: { slug: $component }) {
			items {
				... on PersonCard {
					name
					personName
					designation
					personTagline
					details {
						json
					}
					slug
					image {
						... on LogoBox {
							image {
								url
								height
								width
								title
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_COMPANIES_LIST = gql`
	query CompaniesList($component: String!) {
		companiesWeRepresentCollection(limit: 1, where: { name: $component }) {
			items {
				heading
				description {
					json
				}
			}
		}
	}
`;

export const BLOG_ITEM_LISTING_SETTINGS = gql`
	query BlogListings($component: String!) {
		blogListingCollection(limit: 1, where: { name: $component }) {
			items {
				blogPageHeading
				pageDescription
				featuredPost {
					... on NewsroomItem {
						articleTitle
						slug
						date
						imageUrl
						tags
					}
				}
				seoData {
					... on SeoMetadata {
						title
						metaDescription
						metaRobots
						metaDataBase
						metaImage {
							title
							url
						}
						jsonLd
					}
				}
			}
		}
	}
`;

export const GET_BLOG_DETAILS = gql`
	query BlogSetails($component: String!) {
		blogsCollection(where: { slug: $component }) {
			items {
				blogName
				slug
				publishedDate
				publishedDate
				category
				bannerImage {
					url
				}
				blogText {
					json
				}
				aboutCompany
			}
		}
	}
`;

export const GET_TEXT_SECTION_WITH_BUTTON_AND_IMAGE = gql`
	query imageRichTextButton($component: String!) {
		imageRichTextButtonCollection(limit: 10, where: { name: $component }) {
			items {
				name
				headingTagline
				headingRichText {
					json
				}
				modifierClass
				description {
					json
				}
				image {
					image {
						title
						url
						width
						height
					}
				}
				imagePosition
				backgroundColor
				componentName
				buttonsCollection {
					items {
						title
						buttonUrl
						textColour
						backgroundColour
						borderColour
						containsPlayIcon
						openInNewTab
					}
				}
			}
		}
	}
`;

export const GET_CUSTOMER_SERVICE_FAQ = gql`
	query CustomerServiceFAQ($component: String!) {
		faqModelCollection(limit: 10, where: { name: $component }) {
			items {
				name
				heading
				subHeading
				button {
					title
					buttonUrl
					textColour
					backgroundColour
					borderColour
					type
					openInNewTab
				}
				questionAndAnswersCollection {
					items {
						question
						answer
						richTextAnswers {
							json
						}
					}
				}
				componentName
			}
		}
	}
`;

export const GET_ALL_FRANCHISE_FAQ = gql`
	query CustomerServiceFAQ {
		faqItemCollection(where: { tags_contains_all: ["Franchising"] }) {
			items {
				question
				answer
				richTextAnswers {
					json
				}
			}
		}
	}
`;

export const GET_HALF_TEXT_IMAGE_SECTION_HALF_FORM = gql`
	query HalfTextImageSectionHalfForm($component: String!) {
		formSectionCollection(limit: 10, where: { name: $component }) {
			items {
				name
				headingTagline
				heading
				subHeading
				image {
					image {
						title
						url
						height
						width
					}
				}
				button {
					title
					buttonUrl
					textColour
					backgroundColour
					borderColour
					type
				}
				formId
				formFieldCollection {
					items {
						... on Form {
							name
							id
							labelText
							inputType
							placeholder
							selectOptions
							textInForm
							isRequired
							validationRegex
							validationErrorMessage
						}
					}
				}
			}
		}
	}
`;
export const TEST_QUERY = gql`
	query privacyDetails {
		nameRichTextCollection {
			items {
				details {
					json
				}
			}
		}
	}
`;

export const GET_SLIDER_BOX_WITH_TEXT_DESCRIPTION = gql`
	query sliderBoxWithDescription($component: String!) {
		sliderBoxCollection(limit: 10, where: { name: $component }) {
			items {
				name
				heading
				description
				richTextDescription {
					json
				}
				iconCollection {
					items {
						title
						image {
							title
							url
							height
							width
						}
					}
				}
			}
		}
	}
`;

export const GET_STEP_SECTION_DATA = gql`
	query StepSection($component: String!) {
		stepSectionCollection(limit: 1, where: { name: $component }) {
			items {
				sectionHeading {
					json
				}
				sectionHeadingImage {
					url
					title
					width
					height
				}
				stepCardCollection {
					items {
						subHeading
						heading
						description
						image {
							image {
								url
								title
								width
								height
							}
						}
						customClass
					}
				}
				componentName
			}
		}
	}
`;

export const GET_HEADING_WITH_ICON_BUTTON_DATA = gql`
	query HeadingWithIcon($component: String!) {
		headingSectionWithIconsAndButtonCollection(
			limit: 1
			where: { name: $component }
		) {
			items {
				headingTagline
				title
				iconTextSectionCollection {
					items {
						label
						id
						icon {
							url
							title
						}
						buttonTitle
						url
						containsStartAQuoteButton
					}
				}
			}
		}
	}
`;

export const GET_FRANCHISE_NUMBER_CARD = gql`
	query getFranchiseNumberCard($component: String!) {
		headingImageWithNumberComponentCollection(
			limit: 10
			where: { name: $component }
		) {
			items {
				name
				heading
				image {
					title
					url
					height
					width
				}
				numberWithDescriptionCardCollection {
					items {
						name
						heading
						value
						description
					}
				}
				componentName
			}
		}
	}
`;

export const GET_NEWSROOM_SHORT_COMPONENT = gql`
	query NewroomMediumSection($component: String!) {
		imageTextColumnCollection(where: { name: $component }) {
			items {
				headingTagline
				heading
				description
				button {
					title
					buttonUrl
					textColour
					backgroundColour
					borderColour
				}
				buttonRichTextSectionCollection {
					items {
						... on NewsroomItem {
							name
							slug
							articleTitle
						}
					}
				}
			}
		}
	}
`;

export const GET_LIST_SECTION_COMPONENT = gql`
	query ListSectionComponent($component: String!) {
		listSectionCollection(limit: 1, where: { name: $component }) {
			items {
				listItemsCollection {
					items {
						... on MethodCard {
							name
							heading
							description {
								json
							}
							button {
								... on Button {
									title
									buttonUrl
									textColour
									containsPlayIcon
									backgroundColour
									borderColour
									type
									openInNewTab
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const GET_AGENCY_OWNERS_LISTING = gql`
	query agencyOwnersListing($component: String!) {
		listingModelCollection(limit: 10, where: { name: $component }) {
			items {
				name
				heading
				personCardCollection {
					items {
						... on PersonCard {
							name
							personName
							designation
							details {
								json
							}
							image {
								... on LogoBox {
									image {
										title
										url
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_COMPARISON_COMPONENT_DATA = gql`
	query ComparisonModel($component: String!) {
		comaprisonModelCollection(where: { name: $component }) {
			items {
				logo {
					title
					url
				}
				componentTagline
				comparisonItemCollection {
					items {
						name
						headerKey
						headerValue
						newBusinessCommission
						renewals
						totalEstInitialInvestment
						benefits {
							json
						}
					}
				}
			}
		}
	}
`;
export const GET_RICHTEXT_DESCRIPTION_DETAILS = gql`
	query PrivacyPolicy($component: String!) {
		nameRichTextCollection(where: { name: $component }) {
			items {
				name
				details {
					json
				}
			}
		}
	}
`;

export const GET_MEDIUM_DESCRIPTION_BANNER = gql`
	query HomeMediumBanner($component: String!) {
		textImageSectionCollection(where: { name: $component }) {
			items {
				headingTagline
				heading {
					json
				}
				richTextDescription {
					json
				}
				sectionImage {
					url
					title
				}
				imagePosition
			}
		}
	}
`;

export const GET_FRANCHISE_LEADERS_LISTING = gql`
	query getFranchiseLeaderLists($component: String!) {
		listingModelCollection(limit: 10, where: { name: $component }) {
			items {
				heading
				personCardCollection {
					items {
						... on PersonCard {
							name
							slug
							personName
							designation
							details {
								json
							}
							personTagline
							image {
								... on LogoBox {
									image {
										url
										height
										width
										title
									}
								}
							}
						}
						... on AgencyCard {
							name
							agencyName
							location
							url
						}
					}
				}
			}
		}
	}
`;

export const GET_VIDEO_TESTIMONIALS = gql`
	query videoTestimonials($component: String!) {
		listingModelCollection(limit: 10, where: { name: $component }) {
			items {
				name
				heading
				description
				personCardCollection {
					items {
						... on AgencyCard {
							name
							agencyName
							location
							url
						}
					}
				}
			}
		}
	}
`;

export const GET_GENERAL_SITE_SETTINGS = gql`
	query SiteSettings($component: String!) {
		generalSiteSettingsCollection(where: { name: $component }) {
			items {
				seoData {
					... on SeoMetadata {
						title
						metaDescription
						metaRobots
						metaDataBase
						metaImage {
							title
							url
						}
						jsonLd
					}
				}
				favicon {
					title
					url
				}
			}
		}
	}
`;

export const GET_ANNOUNCEMENT_BAR = gql`
	query AnnouncementBar($component: String!) {
		announcementBarCollection(limit: 1, where: { name: $component }) {
			items {
				name
				annoncement {
					json
				}
				hours
			}
		}
	}
`;
